<template>
  <div>
    
      <form autocomplete="off" class="flex flex-col lg:flex-row items-end space-y-2 lg:space-y-0 lg:space-x-2">
        <vs-card>
            <template slot="header">
              <div class="flex justify-end">
                  <vs-button
                    size="normal"
                    icon-pack="feather"
                    @click="saveData"
                    :disabled="consentFormInfo.isDefault"
                    >{{ btnLabel }}</vs-button
                  >
              </div>
            </template>
            <div class="px-3 py-1">
              <label class="block text-base mb-1">Consent Form Name</label>
              <vs-input
                :success="
                  !errors.first('basic.consent_form') &&
                  consentFormInfo.name != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('basic.consent_form') ? true : false"
                v-validate="'required'"
                name="consent_form"
                data-vv-as="Consent Form Name"
                data-vv-scope="basic"
                :danger-text="errors.first('basic.consent_form')"
                placeholder=""
                :disabled="consentFormInfo.isDefault"
                v-model="consentFormInfo.name"
                class="w-full lg:w-3/12 text-base"
              />
              <span class="text-danger text-sm"
                v-if="errors.first('basic.question')"
                >Please input consent form name</span
              >
            </div>
        </vs-card>
      </form>
    
    <vs-card>
      <template slot="header">
        <div class="flex justify-end">
          <vs-button
            :disabled="isAddMode"
            @click="createOrUpdateQuestionForm()">Create Question</vs-button>
        </div>
      </template>
      
      <vs-table :data="questions" class="consentFormDataTable">
        <template slot="thead">
          <vs-th>Position</vs-th>
          <vs-th>Status</vs-th>
          <vs-th>Questions</vs-th>
          <vs-th>Mandatory</vs-th>
          <vs-th>Actions</vs-th>
        </template>
        <template slot-scope="{data}">
          <draggable
            v-model="questions"
            tag="tbody"
            :move="onMove"
            @end="onEnd"
            class="cursor-pointer">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td data-label="Position" :data="data[indextr].position">
                  <span class="inline-flex">
                    <span class="mr-2">{{indextr + 1}}</span>
                    <vs-icon icon="reorder" size="small"></vs-icon>
                  </span>
                </vs-td>
                <vs-td data-label="Status" :data="data[indextr].isEnabled">
                  <span class="inline-flex">
                    <vs-switch
                      :vs-value="data[indextr]._id"
                      :disabled="isDefault(data[indextr])"
                      v-model="statuses"
                    ></vs-switch>
                  </span>
                </vs-td>
                <vs-td data-label="Questions" :data="data[indextr].question">
                    {{data[indextr].question}}
                </vs-td>
                <vs-td data-label="Mandatory" :data="data[indextr].is_mandatory">
                    {{mandatoryLabel(data[indextr].is_mandatory)}}
                </vs-td>
                <vs-td data-label="Actions" :data="data[indextr].id">
                  <span class="inline-flex" 
                      v-if="!isDefault(data[indextr])">
                    <vs-button
                      type="border"
                      size="small"
                      icon-pack="feather"
                      icon="icon-edit"
                      @click="createOrUpdateQuestionForm(data[indextr]._id)"
                  ></vs-button>
                  </span>
                  <span v-else>...</span>
                </vs-td>
            </vs-tr>
          </draggable>
        </template>
      </vs-table>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import draggable from "vuedraggable";
import vSelect from "vue-select";
import { difference } from "lodash" 

export default {
  components: {
    draggable,
    "v-select": vSelect,
  },
  data:()=>({
    consentFormInfo: {
      name: "",
      isDefault: false
    },
    questions: [],
    statuses: [],
    initialLoad: true
  }),
  methods: {
    ...mapActions("consentForms", [
      "fetchConsentFormInfo", "createConsentForm",
      "updateConsentForm", "updateConsentFormQuestion",
      "reOrderConsentFormQuestion"
    ]),
    ...mapActions("questionnaire", ["fetchTreatmentPlans"]),
    createOrUpdateQuestionForm(id = null) {
      const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)
      const path = role === 'Org Owner' ? 'org-owner' : 'nurse'
      const mode = id ? `${id}/update` : "create"

      this.$router.push(`/${path}/consent-forms/${this.consentFormInfo._id}/question/${mode}`)
    },
    async fetchConsentInfoAndQuestions() {
      try {
        const { params } = this.$route;
        const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
        const result = await this.fetchConsentFormInfo({
          id: params.consentId,
          params: {
            orgId: org_id
          }
        })
        const info = result.data.data;

        this.consentFormInfo = info
        this.questions = info.questions
        this.statuses = info.questions.filter((item) => item.isEnabled).map((item) => item._id)

        this.$nextTick(function() {
          this.initialLoad = false
        })
      } catch (error) {
        console.log(error)
      }
    },
    mandatoryLabel(isMandatory) {
      if (typeof isMandatory === "boolean") {
        return isMandatory ? "Yes" : "No"
      }
      return "Yes"
    },
    onMove(evt) {
      const { isDefault } = evt.draggedContext.element;
      const target = evt.relatedContext.element
      if (isDefault === undefined || target.isDefault === undefined) return false
      return true
    },
    onEnd(evt) {
      const { newIndex, oldIndex } = evt;
      if (newIndex !== oldIndex) {
        const questions = this.questions.map((item, index) => ({
          id: item._id,
          order: index + 1
        }))

        this.reOrderConsentFormQuestion({
          questions
        })
      }
    },
    saveData() {
      if (this.isAddMode) return this.createConsentFormProcess()
      this.updateConsentFormProcess();
    },
    async createConsentFormProcess() {
      const self = this;
      try {
        let isValid = await this.$validator.validateAll("basic");
        await this.$validator.validate();

        if (isValid) {
          const org_id = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)
          const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)

          const payload = {
            name: this.consentFormInfo.name,
            isDefault: false,
            status: true,
            userId: this.$store.state.AppActiveUser.id
          };

          if (org_id) payload['orgId'] = org_id

          const result = await this.createConsentForm(payload)
          const info = result.data.data

          this.consentFormInfo = info

          self.$vs.notify({
            title: "Custom Consent Form creation",
            text: "Custom Consent Form creation successfully.",
            color: "success",
          });

          const path = role === 'Org Owner' ? 'org-owner' : 'nurse'
          this.$router.push(`/${path}/consent-forms/${info._id}/details`)
        }
      } catch (error) {
        self.$vs.notify({
          title: "Failed",
          text: "Failed to create custom consent form. Try again!",
          color: "danger",
        });

        console.log("Error create consent form: " + error)
      }
    },
    async updateConsentFormProcess() {
      const self = this;
      try {
        let isValid = await this.$validator.validateAll("basic");
        await this.$validator.validate();

        if (isValid) {
          const role = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`)

          const payload = {
            id: this.consentFormInfo._id,
            body: {
              name: this.consentFormInfo.name,
            }
          };

          await this.updateConsentForm(payload)

          self.$vs.notify({
            title: "Custom Consent Form update",
            text: "Custom Consent Form update successfully.",
            color: "success",
          });

          const path = role === 'Org Owner' ? 'org-owner' : 'nurse'
          this.$router.push(`/${path}/consent-forms/${this.consentFormInfo._id}/details`)
        }
      } catch (error) {
        self.$vs.notify({
          title: "Failed",
          text: "Failed to update custom consent form. Try again!",
          color: "danger",
        });

        console.log("Error update consent form: " + error)
      }
    },
    isDefault(question) {
      if (typeof(question.isDefault) === 'boolean') return question.isDefault
      return true;
    }
  },
  computed: {
    btnLabel() {
      if ((this.$route.name).search('Create') !== -1) return "Save"
      return "Update"
    },
    isAddMode() {
      return this.btnLabel === 'Save'
    }
  },
  async created() {
    if ((this.$route.name).search('Details') !== -1) this.fetchConsentInfoAndQuestions()
  },
  watch: {
    'statuses': async function(newVal, oldVal) {
      if (!this.initialLoad) {
        const added = difference(newVal, oldVal)
        const removed = difference(oldVal, newVal)

        const payload = {
          consentId: this.consentFormInfo._id,
        };

        if (added.length) {
          payload['id'] = added[0]
          payload['body'] = {
            isEnabled: true
          }
        }
        if (removed.length) {
          payload['id'] = removed[0]
          payload['body'] = {
            isEnabled: false
          }
        }

        await this.updateConsentFormQuestion(payload)
      }
    }
  }
}
</script>

<style>
.consentFormDataTable td:first-child{
  white-space: normal;
}

/* Responsive <table> */
@media screen and (max-width: 600px) {
  .consentFormDataTable .vs-table--header {
      margin-bottom: 1rem;
  }
  .consentFormDataTable .vs-table--tbody-table {
      min-width: unset;
  }
  .consentFormDataTable .vs-table--thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    display: none !important;
  }
  .consentFormDataTable .vs-table--tr {
    display: block;
    margin-bottom: .625em;
  }
  .consentFormDataTable .vs-table--td {
    border-bottom: 1px solid #eee;
    display: block;
    font-size: 1rem;
    text-align: right;
  }
  .consentFormDataTable .vs-table--td:first-child{
    min-width: 230px;
  }
  .consentFormDataTable .vs-table--td::before {
    content: attr(data-label);
    float: left;
    font-weight: 500;
    font-size: 13px;
  }
  .consentFormDataTable .vs-table--td:last-child {
    border-bottom: 0;
  }
}

.vs-input--input,
.vs-input--input.normal {
  font-size: 16px !important;
}
</style>